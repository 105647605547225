import "slick-carousel";

$(document).ready(function() {
  $(".hero_slider_wrapper").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    appendDots: $(".hero_slider_cb"),
  });

  $(".hero_slider_cb ul button").each(function() {
    const buttonText = $(this).text();
    const buttonNumber = parseInt(buttonText);
    if (buttonNumber < 10 && buttonNumber >= 0) {
      $(this).text("0" + buttonNumber);
    }
  });

  $(".pick_up_slider").slick({
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    appendDots: $(".pick_up_cb"),
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  // side menu
  $(".burger_menu_cb").on("click", () => {
    $(".menu_header_cb").addClass("open");
    $("body").addClass("scroll_blocked");
  });

  $(".menu_header_cb .menu_overlay,.menu_content_header_close").on(
    "click",
    () => {
      $(".menu_header_cb").removeClass("open");
      $("body").removeClass("scroll_blocked");
    }
  );

  $(".more-certified-clinic").on("click", function() {
    $(this).remove();
    $(".more-clinic-item").each(function() {
      $(this).removeClass("hidden");
    });
  });
});
