$(document).ready(function() {
  "use strict";

  // SmartPhoneMenu /////////////////////////////////////////////////////////////

  $("#sp_nav").click(function() {
    $("#sp_nav_menu").slideToggle();
  });
  $(".container, #footer_menu, footer").click(function() {
    if ($("#sp_nav_menu").css("display") === "block") {
      $("#sp_nav_menu").slideToggle();
    }
  });

  var $header = $("header");
  var w = $(window).width();
  var x = 768;
  $(function() {
    w = $(window).width();
    $(window).resize(function() {
      header_lock();
    });

    function header_lock() {
      w = $(window).width();
      if (w > x) {
        // ＰＣ時の処理
        $("header").removeClass("fixed");
        if ($("#sp_nav_menu").css("display") === "block") {
          $("#sp_nav_menu").css("display", "none");
        }
      }
    }
  });

  function header_fixScroll() {
    var $scroll_limit = $header.height() + 5;
    if ($(window).scrollTop() > $scroll_limit) {
      $header.css("top", 0);
      $header.addClass("fixed");
      $header.removeClass("normal");
      $(".top_image2").css("margin-top", $scroll_limit);
      $(".video_screen").css("margin-top", $scroll_limit);
      $(".breadcrumb").css("margin-top", $scroll_limit);
      if ($(".top_image2").css("display") === "none") {
        $(".container").css("margin-top", $scroll_limit);
      }
      if ($(".video_screen").css("display") === "none") {
        $(".container").css("margin-top", $scroll_limit);
      }
    } else if ($(window).scrollTop() <= 1) {
      $header.css("top", -$scroll_limit);
      $header.removeClass("fixed");
      $header.addClass("normal");
      $(".top_image2").css("margin-top", "0");
      $(".video_screen").css("margin-top", "0");
      $(".breadcrumb").css("margin-top", "0");
      if ($(".top_image2").css("display") === "none") {
        $(".container").css("margin-top", "0px");
      }
      if ($(".video_screen").css("display") === "none") {
        $(".container").css("margin-top", "0px");
      }
    }
  }

  try {
    window.addEventListener("scroll", header_fixScroll, false);
  } catch (e) {
    window.attachEvent("onscroll", header_fixScroll);
  } /*$(function() {
    if( w <1000){
      $("#catch_video").attr('src','//player.vimeo.com/video/254473619?autoplay=1&loop=0&autopause=0&background=1');
    }else{
      $("#catch_video").attr('src','//player.vimeo.com/video/254473619');
    }
  });*/

  //videoのパラメーターをデバイスごとに変更
  //useragentによってvideoの表示非表示を変更
  $(function() {
    var ua = navigator.userAgent;
    if (
      ua.indexOf("iPhone") > 0 ||
      ua.indexOf("iPod") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      // スマートフォン用コード
      $("#catch_video").css("display", "none");
      $(".catch_copy").css("display", "block");
    } else if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
      // タブレット用コード
      $("#catch_video").css("display", "none");
      $(".catch_copy").css("display", "block");
    } else {
      // PC用コード
      $("#catch_video").css("display", "block");
      $(".top_image3").css("background-image", "none");
    }
  });

  $(function() {
    //クリックしたときのファンクションをまとめて指定
    $(".tab li").click(function() {
      //.index()を使いクリックされたタブが何番目かを調べ、
      //indexという変数に代入します。
      var index = $(".tab li").index(this);

      //コンテンツを一度すべて非表示にし、
      $(".content li").css("display", "none");

      //クリックされたタブと同じ順番のコンテンツを表示します。
      $(".content li")
        .eq(index)
        .css("display", "block");

      //一度タブについているクラスselectを消し、
      $(".tab li").removeClass("select");

      //クリックされたタブのみにクラスselectをつけます。
      $(this).addClass("select");
    });
  });

  //more
  var flg;
  $(function() {
    $(".more").click(function() {
      var stat = true;
      //$(".more_article").slideToggle();
      if (flg === true) {
        $(this)
          .next()
          .slideToggle();

        if (
          $(this)
            .children("i")
            .hasClass("fa-plus-circle")
        ) {
          $(this)
            .children("i")
            .removeClass("fa-plus-circle");
          $(this)
            .children("i")
            .addClass("fa-minus-circle");
          stat = true;
        } else if (
          $(this)
            .children("i")
            .hasClass("fa-minus-circle")
        ) {
          $(this)
            .children("i")
            .addClass("fa-plus-circle");
          $(this)
            .children("i")
            .removeClass("fa-minus-circle");
          stat = false;
        }
      } else {
        return false;
      }
    });
  });

  var windowWidth = $(window).width();
  if (windowWidth <= 414) {
    $(".more")
      .children("i")
      .addClass("fa-plus-circle");
  }
  $(window).on("load resize", function() {
    // 処理を記載
    windowWidth = $(window).width();
    flg = 1;
    if (windowWidth > 414) {
      flg = false;

      $("#f_search").css("display", "block");
      $("#h_search").css("display", "block");

      if (
        $(".more")
          .children("i")
          .hasClass("fa-plus-circle")
      ) {
        $(".more")
          .children("i")
          .removeClass("fa-plus-circle");
        $(".more")
          .children("i")
          .addClass("fa-film");
      } else if (
        $(".more")
          .children("i")
          .hasClass("fa-minus-circle")
      ) {
        $(".more")
          .children("i")
          .removeClass("fa-minus-circle");
        $(".more")
          .children("i")
          .addClass("fa-film");
      }
      //console.log("PC");
    } else {
      flg = true;

      $(".more")
        .children("i")
        .removeClass("fa-film");
      $(".more")
        .children("i")
        .removeClass("fa-list");
      $(".more")
        .children("i")
        .addClass("fa-plus-circle");
      $("#f_search").css("display", "none");
      $("#h_search").css("display", "none");

      //console.log("SP");
    }
  });
});
