const MD_SCREEN_WIDTH = 769;

$(document).ready(function() {
  let isLoading = false;
  function onModalOpen() {
    $("#show_timeTable").on("click", () => {
      if (isLoading) return;
      isLoading = true;
      if ($("#working-hour-modal").children().length == 0) {
        $.ajax({
          type: "GET",
          dataType: "JSON",
          url: window.location.pathname,
          success: function(data) {
            $("#working-hour-modal").append(data.html);
            modalOpen();
            onModalClose();
            isLoading = false;
          },
        });
      } else {
        modalOpen();
        isLoading = false;
      }
    });
  }

  function modalOpen() {
    $(".clinic .overlay").addClass("show");
    $(document).on("click", ".clinic .overlay .wrapper", function(e) {
      e.stopPropagation();
    });
    $("body").addClass("locked");
  }

  function onModalClose() {
    $(document).on(
      "click",
      ".overlay, .clinic .overlay .wrapper .close_icon",
      function() {
        $(".clinic .overlay").removeClass("show");
        $("body").removeClass("locked");
        $("header").css("padding-right", 0);
        if (window.innerWidth > MD_SCREEN_WIDTH) {
          $(".clinic .overlay .wrapper").css("margin-right", -15);
        }
      }
    );
  }

  onModalOpen();
});
