const selectIds = ["#prefecture_selector_cb", "#city_selector_cb"];

$(document).ready(function() {
  function addSelectAction() {
    selectIds.forEach(function(element) {
      const customSelect = $(element);
      const selectInputWrapper = customSelect.find(".select_input_wrapper");
      const inputElement = selectInputWrapper.find(".select_box_cb_input");
      const formInputElement = selectInputWrapper.find(
        ".select_box_cb_form_input"
      );
      const itemWrapper = customSelect.find(".select_items");
      const items = itemWrapper.find(".select_item");

      items.on("click", function() {
        const selectedItem = $(this);
        const selectedText = selectedItem.text();
        const selectedValue = selectedItem.attr("value");
        if (element == selectIds[0]) {
          citySelectBehavior(selectedText, selectedValue);
        }

        inputElement.val(selectedText);
        formInputElement.val(selectedValue);
        items.removeClass("selected");
        selectedItem.addClass("selected");

        itemWrapper.removeClass("show").slideUp(300);
        selectInputWrapper.removeClass("active");
      });

      selectInputWrapper.on("click", function() {
        $(".select_items")
          .not(itemWrapper)
          .removeClass("show")
          .slideUp(300);
        $(".select_input_wrapper")
          .not(selectInputWrapper)
          .removeClass("active");

        itemWrapper.toggleClass("show").slideToggle(300);
        selectInputWrapper.toggleClass("active");
      });

      $(document).on("click", function(event) {
        if (!$(event.target).closest(".custom_select_cb").length) {
          $(".select_input_wrapper").removeClass("active");
          $(".select_items")
            .removeClass("show")
            .slideUp(300);
        }
      });
    });
  }

  window.addEventListener("resize", addCategoryAction);

  function addCategoryAction() {
    const $toggleMenuCb = $("#toggle_menu_cb");
    const $toggleMenuText = $toggleMenuCb.find("p");
    const $searchMenuWrapper = $(".search_menu_wrapper");
    const $categoryNameCb = $(".categoryName_cb");

    if (window.matchMedia("(max-width: 800px)").matches) {
      $toggleMenuCb.off("click").on("click", function() {
        $searchMenuWrapper.slideToggle(300);
        $toggleMenuText.text(
          $toggleMenuText.text() === "絞り込み検索" ? "閉じる" : "絞り込み検索"
        );
      });

      $categoryNameCb.off("click").on("click", function() {
        const $categoryItem = $(this).parent();
        $categoryItem.find(".checkbox_list_cb").slideToggle(300);
        $categoryItem.find(".categoryName_cb img").toggleClass("active");
      });
    } else {
      $toggleMenuCb.off("click");
      $categoryNameCb.off("click");
      const $categoryItem = $categoryNameCb.parent();
      $categoryItem.find(".checkbox_list_cb").css("display", "flex");
      $categoryItem.find(".categoryName_cb img").toggleClass("active");
      $searchMenuWrapper.show();
    }
  }

  function citySelectBehavior(selectedText, selectedValue) {
    const citySelector = $("#city_selector_cb");
    const citySelectBox = citySelector.find(".select_input_wrapper");
    const cityInput = citySelectBox.find(".select_box_cb_input");
    const cityFormInput = citySelectBox.find(".select_box_cb_form_input");
    const cityItems = citySelector.find(".select_item");

    citySelector.removeClass("disabled");
    if (selectedText === $("#prefecture_input_cb").val()) return;
    cityInput.val("");
    cityFormInput.val("");
    cityItems.removeClass("selected");

    $.ajax({
      type: "GET",
      dataType: "JSON",
      url: "/clinics/cities_by_prefecture",
      data: { prefecture_code: selectedValue },
      success: function(data) {
        if (data.city_selection) {
          citySelector.removeClass("disabled");
        } else {
          citySelector.addClass("disabled");
        }
        citySelector
          .find(".select_items")
          .html("")
          .append(data.html);

        const customSelect = $("#city_selector_cb");
        const selectInputWrapper = customSelect.find(".select_input_wrapper");
        const inputElement = selectInputWrapper.find(".select_box_cb_input");
        const formInputElement = selectInputWrapper.find(
          ".select_box_cb_form_input"
        );
        const itemWrapper = customSelect.find(".select_items");
        const items = itemWrapper.find(".select_item");

        items.on("click", function() {
          const selectedItem = $(this);
          const selectedText = selectedItem.text();
          const selectedValue = selectedItem.attr("value");
          inputElement.val(selectedText);
          formInputElement.val(selectedValue);
          items.removeClass("selected");
          selectedItem.addClass("selected");

          itemWrapper.removeClass("show").slideUp(300);
          selectInputWrapper.removeClass("active");
        });
      },
    });
  }

  addSelectAction();
  addCategoryAction();
});
